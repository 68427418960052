export default {
    fonts: {
        main: "Open Sans, sans-serif",
        alt: "Roboto Condensed, sans-serif",
    },
    colors: {
        main: "hsl(207, 70%, 59%)",
        dark: "hsl(227, 2%, 12%)",
        ligth: "hsl(0, 0%, 97%)",
        white: "hsl(0, 0%, 100%)",
        gray: "var(--ext-gray-4)",
        gray2: "var(--ext-gray-2)",
        black: "hsl(0, 0%, 10%)",
        purple: "var(--ext-purple)",
        blue: "var(--ext-blue)"
    },
    breackpoints: {
        //mobile: "only screen and (max-width:50rem)",
        //tablet: "only screen and (max-width:65rem)",
        mobile: "only screen and (min-width:480px)",
        tablet: "only screen and (min-width:768px)",
        desktop: "only screen and (min-width:1024px)",
    },
    spacings: {
        xSmall: ".25rem",
        small: ".5rem",
        normal: "1rem",
        medium: "2rem",
        large: "3rem",
        xLarge: "4rem",
    },
    animations: {
        button: "box-shadow 0.3s ease",
        link: "color 0.2s ease",
    },
    shadows: {
        shadow1: "0px 5px 20px rgba(30, 30, 31, 0.05)",
    },
}
