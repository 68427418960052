// You can delete this file if you're not using it
import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import Theme from "./src/themes/theme"
//import './src/styles/media.css'
//background-color: ${props => props.theme.colors.white};
//color: ${props => props.theme.colors.gray};
import "./src/styles/global.css"

const GlobalStyles = createGlobalStyle`

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    body, html {
        height: 100%;
        font-family: ${props => props.theme.fonts.main};
    }

    body h1, body h2, body h3, body h4, body h5 {
        font-family: ${props => props.theme.fonts.alt};
    }
    
`
export const wrapRootElement = ({element}) => (
    <ThemeProvider theme={Theme}>
        <GlobalStyles />
        {element}
    </ThemeProvider>
)
